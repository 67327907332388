import axios from 'axios'
import { Logger } from "../lib/logger"
import CryptoJS from "crypto-js"
const log = Logger("app:baidu-ai")

const KP = {
  key: '1234567812345678', // 秘钥 16*n
  iv: '1234567812345678'  // 偏移量
}

function getAesString(data, key, iv) { // 加密
    key = CryptoJS.enc.Utf8.parse(key)
    iv = CryptoJS.enc.Utf8.parse(iv)
    let encrypted = CryptoJS.AES.encrypt(data, key,
        {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        })
    return encrypted.toString()    // 返回的是base64格式的密文
}

function getDAesString(encrypted, key, iv) { // 解密
    key = CryptoJS.enc.Utf8.parse(key)
    iv = CryptoJS.enc.Utf8.parse(iv)
    let decrypted = CryptoJS.AES.decrypt(encrypted, key,
        {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        })
    return decrypted.toString(CryptoJS.enc.Utf8)
}

// AES 对称秘钥加密
const aes = {
  en: (data) => getAesString(data, KP.key, KP.iv),
  de: (data) => getDAesString(data, KP.key, KP.iv)
}

// BASE64
const base64 = {
    en: (data) => CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data)),
    de: (data) => CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8)
}

// SHA256
const sha256 = (data) => {
    return CryptoJS.SHA256(data).toString()
}

// MD5
const md5 = (data) => {
  return CryptoJS.MD5(data).toString()
}

//axios
const request = axios.create({
    baseURL: 'https://chat.3-3.me',
    timeout: 5000
})

request.interceptors.request.use(config => {
    console.log(config)
    let headers = {
        'Content-Type': 'application/json; charset=utf-8'
    }
    config.headers = {...headers, ...config.headers}
    
    if(config.headers['Content-Type'] === 'multipart/form-data') {
        return config
    }
    
    let rs = ''
    if(config.data && config.data.constructor.name !== 'FormData') {
        if(config.data.hasOwnProperty('r')) {
             delete config.data.r
             //rs = base64.en(config.data)
             //config.data = rs
        }
    }
    config.headers.signature = KP.key
    
    console.log(config)
    return config
})

request.interceptors.response.use(response => {
    let res = response.data
    if(typeof res === 'string') {
        res = res ? JSON.parse(res) : res
    }
    return res
}, error => {
    log('err', error)
    return Promise.reject(error)
})

export default request
